* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}
.Footer {
  width: 100vw;
  height: 700px;
}
body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -90px;
  right: -110px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(1rem, 3vw, 4rem);
  color: rgba(22, 61, 98, 1);
  /* color: #fe9e0d; */
  /* line-height: 5rem; */
  max-width: 600px;
}

.primary-heading_orange {
  font-size: clamp(1rem, 3vw, 4rem);
  color: #fe9e0d;
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}


.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}

.contact-form2-container {
  background-color: white;
  max-width: 540px;
  width: 100%;
  margin-top: 3rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  padding-right: 5rem;
  border-radius: 1rem;
  font-size: large;
}
.contact-form2-container img {
  max-width: 120px;
}
.contact-location-container img {
  max-width: 100px;
}
.last {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.contact-location-container {
  background-color: white;
  max-width: 540px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  padding-right: 1rem;
  border-radius: 1rem;
}

.contact-form-container p {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}
/* .footer-logo-container {
  max-width: 110px;
} */
.footer-logo-container img{
  max-width: 210px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}

#review {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-weight: 100;
}
#review p {
  text-align: left;
  font-weight: 100;
}
.testimonial-section-bottom {
  margin-top: 1rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: 100;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 4rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.wrapper {
  width: 100%;
  height: 300px;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  height: 100px;
  margin-top: 5rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}
@keyframes scrollRight {
  to {
    right: -200px;
  }
}

.item {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  position: absolute;
  left: max(calc(200px * 8), 100%);
  animation-name: scrollLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.item img{
  max-width: 150px;
  max-height: 140px;
}
.itemR {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  position: absolute;
  right: max(calc(200px * 8), 100%);
  animation-name: scrollRight;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.itemR img{
  max-width: 150px;
  max-height: 90px;
}
.item1 {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}

.vm {
text-align: center  !important;
align-items: center  !important;
}
.vm h1{
  color: rgba(22, 61, 98, 1);
  }
  .vm p{
    color: rgb(63, 63, 63);
    font-size: large;
    }

.info-boxes-img-container {
  max-width: 300px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-section-info h2 {
  margin: 1rem 0rem;
}

.nav-logo-container img {
  width: 70px;
}
#Des {
  padding-left: 2rem;
  padding-right: 2rem;
  height: 250px;
  text-align: center;
}
#Des p{
  text-align: center;
}

#Des h1{
  text-align: center;
  margin-bottom: 10px;
}

.work-section-bottom {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}



.blur-background {
  filter: blur(10px);
  pointer-events: none;
}

.work-section-info img {
  max-width: 300px;
  margin: 0;
}
.work-section-info {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 3rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  height: 650px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: 100;
}

.inner-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 600px;
}
.work-section-info-card {
  background-color: white;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90vw;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: 100;
}
.work-section-info-card-long {
  background-color: white;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 95vh;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: 100;
}
.inner-box-card-long {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-radius: 2rem;
  height: 80%;
  flex-direction:column;
  text-align: start;
  font-size: small;
}

.inner-box-card-long img {
  max-width: 200px;
}
.inner-box-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90vw;
  border-radius: 2rem;
  height: 300px;
  flex-direction:column;
  font-weight: 100;
  text-align: center;
}
.inner-box-card p {
  text-align: center;
}
#Des-card{
  width: 65%;
  text-align: center;
  align-items: center;
}

#Des-card p{
  text-align: center;
}

.learn-more-button-card {
  padding: 0px 120px 0px 120px;
  height: 60px;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 1s;

}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.learn-more-button {
  padding: 0px 40px 0px 40px;
  height: 60px;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 1s;

}
.learn-more-button:hover {
  transition: 1s;
  background-color: #ffb649;
  cursor:pointer;
}
.end {
  bottom: 0px;
}
h2 {
  text-align: center;
}
.info-wide {
  margin-bottom: 3rem;
  background-color: white;
  padding: 1.5rem 1rem 1.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 740px;
  border-radius: 2rem;
  max-height: 200px;
  flex-direction:column;
  text-align: center;
  font-weight: 100;
}

.info-wide img{
  max-height: 200px;
  max-width: 240px;
}

.work-section-info-wide {
  margin-right: 1rem;
  margin-bottom: 3rem;
  background-color: white;
  padding: 1.5rem 0rem 1.5rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1290px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: 100;
}
iframe {
  margin-top: 40px;
}
.Housekeeping-info {
  left: 0px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 3rem;
  background-color: white;
  padding: 1.5rem 1rem 1.5rem 0rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1190px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  font-weight: 100;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: left;
  text-align: left;
  width: 280px;
  font-weight: 300;
}


.work-section-info h4 {
  flex: 3;
  display: flex;
  max-height: 20px;
  font-weight: 600;
}
@media (max-width: 1290px) {
  .work-section-info {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .contact-form2-container {
    padding-right: 3rem;
  }
} 
@media (max-height: 700px) and (max-width: 700px){
  .inner-box-card p {
    font-size: x-small;
  }
  .learn-more-button-card {
    padding: 0px 50px;
  height: 35px;
  width: 70%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  color: white;
  transition: 1s;
  }
  .work-section-info-card {
    height: 95vh;
    width: 100vw;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }
  .inner-box-card {
    width: 90vw;
    border-radius: 2rem;
    height: 100%;
  }
}
@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
  #Des {
    padding-left: 1rem;
  }
  .info-wide {
    max-height: 800px;
    padding: 0rem 2rem;
    width: 340px;
  }
  .info-wide p{
    height: 200px;
  }
  .Housekeeping-info {
    height: 700px;
    width: 350px;
  }
  #Des2 {
    font-size: large;
  }
  .inner-box-card img{
    max-width: 180px;
    max-height: 180px;
  }
  .work-section-info-card {
    height: 95vh;
  }
  .inner-box-card {
    width: 90vw;
    border-radius: 2rem;
    height: 100%;
  }
  iframe {
    width:560px ;
    height:315px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 3rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }

  .contact-form-container p {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
  #Des-card {
    width: 95%;
  }
  .inner-box-card {
    height: 95vh;
  }
  .work-section-info-card-long {
    background-color: white;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95vw;
    height: 95vh;
  }
  .inner-box-card-long {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 2rem;
    height: 85%;
    flex-direction:column;
    text-align: start;
    font-size: small;
  }
  .inner-box-card-long img{
    height: 100px;
  }
  .inner-box-card-long p{
    font-size: small;
  }
  iframe {
    margin-left: 20px;
    width:460px;
    height:265px;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
  .contact-form2-container {
    padding-right: 1rem;
  }
  .inner-box-card img {
    max-width: 200px;
    max-height: 200px;
  }
  iframe {
    width:350px;
    height:200px;
  }
}

 @media (max-width: 400px) {
  iframe {
    width:300px;
    height:180px;
  }
}
